const baseURL = process.env.NEXT_PUBLIC_BASE_URL
const apiKey = process.env.NEXT_PUBLIC_API_KEY

export const LoginApi = async (url, formData) => {
  const response = await fetch(baseURL + url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      Connection: 'keep-alive',
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
    body: JSON.stringify(formData), // body data type must match "Content-Type" header
  })
  return response.json() // parses JSON response into native JavaScript objects
}

export const PostData = async (url = 'admin', token, formData) => {
  const response = await fetch(baseURL + url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      Authorization: token,
    },
    body: JSON.stringify(formData), // body data type must match "Content-Type" header
  })
  if (response.status === 401) {
    return response
  } else {
    return response.json()
  }
}

export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const getLatestUserDetails = async (
  setUserResultDispatch,
  token,
  apiPayload,
) => {
  PostData('admin', token, apiPayload).then((response) => {
    if (response.status === 401) {
      const loggedOutTimeout = () => {
        setTimeout(() => {
          window.location.href = '/'
        }, 4000)
      }

      loggedOutTimeout()
      clearTimeout(loggedOutTimeout)

      return false
    }

    if (response.message) {
      const loggedOutTimeout = () => {
        setTimeout(() => {}, 4000)
      }

      loggedOutTimeout()
      clearTimeout(loggedOutTimeout)

      return false
    }

    // Catch when users array doesn't exist
    if (response.data.users.length <= 0) {
      const loggedOutTimeout = () => {
        setTimeout(() => {}, 4000)
      }

      loggedOutTimeout()
      clearTimeout(loggedOutTimeout)

      return false
    }

    if (response.data.users.length > 0) {
      setUserResultDispatch({
        didUpdateInitialCredentials:
          response.data.users[0].didUpdateInitialCredentials,
        email: response.data.users[0].email,
        id: response.data.users[0].id,
        phoneNumber: response.data.users[0].phoneNumber,
        tempAuthCode: response.data.users[0].tempAuthCode,
        appleTransactions: response.data.users[0].userData.appleTransactions,
        deviceTokens: response.data.users[0].userData.deviceTokens,
        fulfilledPromotions:
          response.data.users[0].userData.fulfilledPromotions,
        gender: response.data.users[0].userData.gender,
        genderPref: response.data.users[0].userData.genderPref,
        isBanned: response.data.users[0].userData.isBanned,
        isOptedIntoMarketingEmails:
          response.data.users[0].userData.isOptedIntoMarketingEmails,
        language: response.data.users[0].userData.language,
        minutes: response.data.users[0].userData.minutes,
        totalFreeMinutes: response.data.users[0].userData.totalFreeMinutes,
        totalMinutesBought: response.data.users[0].userData.totalMinutesBought,
        totalSpent: response.data.users[0].userData.totalSpent,
        transactions: response.data.users[0].userData.transactions,
        type: response.data.users[0].userData.type,
      })
    }
  })
}
